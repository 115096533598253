import { useEffect, useState } from "react";
import { Button, Upload, message, Table, Spin } from "antd";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { FaArrowDown } from "react-icons/fa6";
import conf from "../config";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../context/Usecontext";
const Importpage = ({ setIslogin }) => {
  const [fileList, setFileList] = useState([]);
  const [show, setshow] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState([]);
  const [promotedata, setPromoteData] = useState([]);
  const [column_names, setColumn_names] = useState([]);

  const customRequest = async () => {
    try {
      setLoading(true);
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      if (fileList.length > 0) {
        const formData = new FormData();

        formData.append("file", fileList[0].originFileObj);
        const response = await axios.post(
          `${conf.baseurl}/upload`,
          formData,
          config
        );

        if (response.data.status === "F") {
          // console.log(response.data);
          setError(response.data.data);
          setLoading(false);
        } else if (response.data.status === "S") {
          // console.log(response);
          setTabledata(response.data.data);
          setError([]);
          setFileList([]);
          setLoading(false);
        }
      } else {
        toast.error("Select the file first");
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        setLoading(false);
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        setLoading(false);
        console.error("Error uploading file:", error);
        toast.error("Failed to upload file");
        console.error("Error:", error.message);
      }
    }
  };

  const column = [
    "Year",
    "SSN",
    "firstname",
    "lastname",
    "email",
    "Department",
    "Title",
    "Manager",
    "extrabonus",
    "Match",
    "Footnote",
    "Salary_Bonus.salary",
    "Salary_Bonus.lowbonusestimate",
    "Salary_Bonus.highbonusestimate",
    "Salary_Bonus.bonusTarget",
    "Salary_Bonus.actualBonus",
    "Insurance.medical",
    "Insurance.lifeinsurance",
    "Insurance.Dental",
    "Insurance.vision",
    "Other_Earnings.field1",
    "Other_Earnings.fieldvalue1",
    "Other_Earnings.field2",
    "Other_Earnings.fieldvalue2",
    "Other_Earnings.field3",
    "Other_Earnings.fieldvalue3",
  ];

  const getpromotedata = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getpromotedata`,
        config
      );
      if (response.data.status == "F") {
        // console.log(response.data);
        console.log("no data available");
      } else {
        setPromoteData(response.data.data);
        const promotedata = response.data.data;
        const allColumnNames = getAllColumns(promotedata);
        console.log("All Column Names:", allColumnNames);
        setColumn_names(allColumnNames);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error fetching data:", error.message);
      }
    }
  };

  const getAllColumns = (promoteData) => {
    // Combine static and dynamic columns
    const allColumns = [...column, ...generateDynamicFields(promoteData)];
    return allColumns;
  };
  const generateDynamicFields = (promoteData) => {
    const fundNames = promoteData.map((item) => item.Fund);

    // Create dynamic columns based on fund names
    const dynamicColumns = [
      ...fundNames.map((fund) => `Promote_Distributions.${fund}`),
      ...fundNames.map((fund) => `Fund_allocations.${fund}`),
      ...fundNames.map((fund) => `EPP_Promote_Distributions.${fund}`),
      ...fundNames.map((fund) => `EPP_Promote_Allocations.${fund}`),
    ];

    return dynamicColumns;
  };

  useEffect(() => {
    getpromotedata();
  }, []);

  const columns = column_names.map((col, ind) => {
    if (ind < 4) {
      return {
        title: col,
        dataIndex: col,
        key: col,
        width: 150,
        fixed: "left",
      };
    } else {
      return {
        title: col,
        dataIndex: col,
        key: col,
        width: 150,
      };
    }
  });

  const handleOnChange = ({ file, fileList, event }) => {
    // console.log(file, fileList, event);
    // Validate the file here
    const isExcel =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (!isExcel) {
      message.error("You can only upload Excel files");
      setFileList([]);
    } else {
      setFileList(fileList);
      setTabledata([]);
    }

    // Update fileList with the validated file
  };

  const onRemove = (file) => {
    setFileList([]);
  };

  const downloadexcel = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${conf.baseurl}/sample_excel`,
        { columns: column_names },
        {
          responseType: "blob",
          headers: config.headers,
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sample.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      // console.error('Error downloading Excel file: ', error);
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error downloading Excel file: ", error);
      }
    }
  };

  const handleimport = async () => {
    try {
      setLoading(true);
      const token = Cookies.get("token");
      console.log(token); // Replace 'yourTokenCookieName' with the actual name of your token cookie
      const config = {
        headers: {
          Authorization: token,
        },
      };

      // const response = await axios.get("http://localhost:5000/api/import", config);
      const response = await axios.get(`${conf.baseurl}/import`, config);
      if (response) {
        if (response.data.status == "S") {
          toast.success("Data updated Succesfully");
          setTabledata([]);
          setLoading(false);
        } else if (response.data.status == "F") {
          toast.error(response.data.message);
          setTabledata([]);
          setLoading(false);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error in uploading: ", error);
      }
    }
  };
  return (
    <div className="container">
      <Spin spinning={loading}>
        <div className="formWrapper">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              padding: 10,
            }}
          >
            <Button
              type="primary"
              style={{
                marginLeft: 5,
                alignSelf: "end",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={downloadexcel}
            >
              <FaArrowDown></FaArrowDown>Sample Excel
            </Button>
          </div>

          <h6 style={{ textAlign: "center" }}>
            Note: Please use the sample file to create your actual data file.
            You can remove unwanted columns. Blank fields,Non-decimal values
            will be ignored while importing.E-mail and Year required
          </h6>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <div style={{ marginRight: 20 }}>
              <Upload
                fileList={fileList}
                customRequest={customRequest}
                onChange={handleOnChange}
                onRemove={onRemove}
                beforeUpload={() => false}
                maxCount={1}
              >
                <Button>Select File & Upload</Button>
              </Upload>
            </div>

            <div>
              <Button key="submit" type="primary" onClick={customRequest}>
                Upload & Preview
              </Button>
            </div>
          </div>
          {tabledata.length > 0 && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginRight: 30,
                }}
              >
                <Button key="submit" type="primary" onClick={handleimport}>
                  Start Import
                </Button>
              </div>
              <div style={{ marginRight: 30, marginLeft: 30 }}>
                <Table
                  columns={columns}
                  dataSource={tabledata}
                  className="grid-table importpageTable"
                  bordered
                  style={{ overflow: "auto", margin: 10, width: "100%" }}
                  pagination={false}
                  scroll={{
                    x: 500,
                    y: 400,
                  }}
                />
              </div>
            </div>
          )}

          {error.length > 0 && (
            <div
              style={{
                border: "1px solid red",
                backgroundColor: "#ffe6e6",
                padding: "10px",
                borderRadius: "5px",
                margin: "10px 0",
              }}
            >
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {error.map((err, index) => (
                  <li key={index} style={{ color: "red", margin: "5px 0" }}>
                    <span style={{ fontWeight: "bold" }}>Error: </span>
                    {err}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default Importpage;
