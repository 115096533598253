import axios from "axios";
import React, { useEffect, useState } from "react";
import conf from "../config";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, Space, Spin, Table, Form, Row, Col, Input } from "antd";

export default function Netincomepage({ setIslogin }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getdata = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getnetincometabel`,
        config
      );
      const data = response.data[0];
      // console.log(data);
      form.setFieldsValue({
        cohort1: data.Cohort1 || "",
        cohort2: data.Cohort2 || "",
        cohort5: data.Cohort5 || "",
        cohort6: data.Cohort6 || "",
        hurdle_income1: data.Hurdle_Income_1 || "",
        hurdle_income2: data.Hurdle_Income_2 || "",
        hurdle_income5: data.Hurdle_Income_5 || "",
        hurdle_income6: data.Hurdle_Income_6 || "",
        AnnualBVNetIncome: data.AnnualBVNetIncome || "",
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in retrieving data:", error.message);
      }
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const onchangedata = async (values) => {
    try {
      const token = Cookies.get("token");
      // console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.put(
        `${conf.baseurl}/editnetincomedata`,
        values,
        config
      );
      toast(response.data.message);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in retrieving data:", error.message);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form
        form={form}
        className="formWrapper"
        name="net_income_form"
        layout="vertical"
        onFinish={onchangedata}
        style={{ width: 900 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="AnnualBVNetIncome"
              name="AnnualBVNetIncome"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="annualBVNetIncome" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Cohort 1"
              name="cohort1"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Cohort 1" disabled />
            </Form.Item>
            <Form.Item
              label="Cohort 2"
              name="cohort2"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Cohort 2" disabled />
            </Form.Item>
            <Form.Item
              label="Cohort 5"
              name="cohort5"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Cohort 5" disabled />
            </Form.Item>
            <Form.Item
              label="Cohort 6"
              name="cohort6"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Cohort 6" disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Hurdle Income 1"
              name="hurdle_income1"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Hurdle Income 1" />
            </Form.Item>
            <Form.Item
              label="Hurdle Income 2"
              name="hurdle_income2"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Hurdle Income 2" />
            </Form.Item>
            <Form.Item
              label="Hurdle Income 5"
              name="hurdle_income5"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Hurdle Income 5" />
            </Form.Item>
            <Form.Item
              label="Hurdle Income 6"
              name="hurdle_income6"
              rules={[
                {
                  pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                  message: "Please enter a valid number.",
                },
              ]}
            >
              <Input placeholder="Hurdle Income 6" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
