import { Button, Modal, Input, Dropdown, Menu, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import conf from "../config";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useMyContext } from "../context/Usecontext";
import Cookies from "js-cookie";
import { FaUserCircle } from "react-icons/fa";

const Nav = ({ setEditdata, handleLogout, setIslogin }) => {
  const navigate = useNavigate();
  const [mailto, setmailto] = useState(false);
  const [email, setEmail] = useState("");
  const { isAdmin, setIsAdmin } = useMyContext();
  const { fname, setFname } = useMyContext();
  const { lname, setLname } = useMyContext();
  const { dataforedit, setDataforedit } = useMyContext();
  const [checked, setChecked] = useState(false);
  const { header, setHeader } = useMyContext();

  const handleOk = async () => {
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    try {
      // console.log("Email submitted:", email);
      if (!email) {
        toast.error("Please fill all the fields");
      } else {
        const token = Cookies.get("token");

        const config = {
          headers: {
            Authorization: token,
          },
        };
        const response = await axios.post(
          `${conf.baseurl}/submitmail`,
          { email: email },
          config
        );

        if (response.data.message === "S") {
          setmailto(false);
          setEmail("");
          // setAnnualBVNetIncome(null);
        } // Assuming the server sends back some response
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error submitting email:", error);
        console.error("Error:", error.message);
      }
    }
  };

  const handleCancel = () => {
    setmailto(false); // Close the modal without submitting
    setEmail(""); // Clear the email input
  };

  const getmskedvalue = async () => {
    try {
      const token = Cookies.get("token");
      // console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getmaskedvalue`,
        config
      );
      // console.log(response);
      if (response.data.data) {
        setChecked(response.data.data[0].masked);
        setEmail(response.data.data[0].mailto);
        // setAnnualBVNetIncome(response.data.data[0].annualBVNetIncome);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getmskedvalue();
  }, [mailto]);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setEditdata(null);
          setDataforedit(null);
          navigate("/report");
        }}
      >
        Generate Report
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setEditdata(null);
          setDataforedit(null);
          navigate("/showdata");
        }}
      >
        Employee Data
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          navigate("/status");
          setDataforedit(null);
          setEditdata(null);
        }}
      >
        Download All Reports
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          navigate("/export");
          setDataforedit(null);
          setEditdata(null);
        }}
      >
        Export
      </Menu.Item>
      <Menu.Item
        key="5"
        onClick={() => {
          navigate("/import");
          setDataforedit(null);
          setEditdata(null);
        }}
      >
        Import
      </Menu.Item>
      <Menu.Item
        key="6"
        onClick={() => {
          setEditdata(null);
          setDataforedit(null);
          setmailto(true);
        }}
      >
        Settings
      </Menu.Item>
      <Menu.Item
        key="7"
        onClick={() => {
          navigate("/employeelist");
          setDataforedit(null);
          setEditdata(null);
        }}
      >
        Employee Login Info
      </Menu.Item>
      <Menu.Item
        key="8"
        onClick={() => {
          navigate("/netincome");
          setDataforedit(null);
          setEditdata(null);
        }}
      >
        Net Income
      </Menu.Item>
      <Menu.Item
        key="9"
        onClick={() => {
          navigate("/promotelist");
          setDataforedit(null);
          setEditdata(null);
        }}
      >
        Promote Table
      </Menu.Item>
      <Menu.Item key="10" onClick={() => handleLogout(navigate)}>
        <RiLogoutCircleRLine /> Logout
      </Menu.Item>
    </Menu>
  );

  const menu2 = (
    <Menu>
      {/* <Menu.Item key="1" onClick={() => { setEditdata(null); navigate('/report') }}>Generate report</Menu.Item> */}
      <Menu.Item key="2" onClick={() => handleLogout(navigate)}>
        <RiLogoutCircleRLine /> Logout
      </Menu.Item>
    </Menu>
  );

  const onmaskedChange = async (e) => {
    // console.log(e.target.checked);
    setChecked(e.target.value);
    try {
      const token = Cookies.get("token");
      // console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.post(
        `${conf.baseurl}/hide`,
        { masked: e.target.checked },
        config
      );
      // console.log(response);
      if (response.data.status === "S") {
        console.log("succesfully updated");
        getmskedvalue();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error:", error.message);
      }
    }
  };

  return (
    <div className="headerBar">
      <nav className="navbar navbar-expand-lg">
        {/* <a className="navbar-brand" href="#">Navbar</a> */}
        <a className="navbar-brand" href="#">
          <img src={require("../assets/white-logo.png")} alt="Logo" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto"></ul>

          <div
            style={{
              color: "white",
              marginRight: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaUserCircle style={{ marginRight: 5 }} size={25} />
            <span style={{ marginRight: 5 }}>{fname}</span>
            <span>{lname}</span>
          </div>

          <Dropdown overlay={isAdmin == 1 ? menu : menu2} trigger={["click"]}>
            <Button className="greport-btn">Menu</Button>
          </Dropdown>

          <h1 className="nav-item navTitle">
            {/* <Link className="nav-link" to='/report' onClick={() => { setEditdata([]) }}>Generate Report</Link> */}
            Compensation Analysis
          </h1>
        </div>
      </nav>

      <Modal
        title="Enter email you want to send report to"
        open={mailto}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <Input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <Checkbox
          onChange={onmaskedChange}
          checked={checked}
          style={{ marginTop: 10, fontWeight: "600" }}
        >
          {" "}
          Enable Mask On Name ans SSN number in report PDF{" "}
        </Checkbox>
      </Modal>
    </div>
  );
};
export default Nav;
