import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useEffect, useState } from "react";
import { useMyContext } from "../context/Usecontext";
import { FaArrowDown } from "react-icons/fa6";
import axios from "axios";
import conf from "../config";
import { Button, Flex, Spin } from "antd";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const Statuspage = ({ setIslogin }) => {
  const navigate = useNavigate();
  const { downloading, setDownloading } = useMyContext();
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [zipbtnshow, setZipbtnshow] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchProgress = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get(`${conf.baseurl}/progress`, config);
      // console.log(response);
      if (response.data) {
        const data = response.data;
        if (data.status === 100) {
          setTimeout(() => {
            setProgress(data.status);
            setDownloading(false);
            setZipbtnshow(true);
          }, 5000);
        } else {
          // console.log(data);
          setProgress(data.status);
        }
      } else {
        console.error("Failed to fetch progress:", response.statusText);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
      }
    }
  };
  useEffect(() => {
    let progressInterval;
    if (downloading) {
      progressInterval = setInterval(fetchProgress, 7000); // Fetch progress every 1 second
    } else {
      clearInterval(progressInterval);
    }

    return () => clearInterval(progressInterval);
  }, [downloading]);

  const fetchProgressatstart = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/progress`, config);

      if (response.data) {
        const data = response.data;
        // console.log(data.status);
        if (data.status === 100) {
          setProgress(data.status);
          setShow(true);
          setZipbtnshow(true);
        } else if (data.status === 0) {
          setShow(false);
        } else {
          setDownloading(true);
          setProgress(data.status);
          setShow(true);
        }
      } else {
        console.error("Failed to fetch progress:", response.statusText);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
      }
    }
  };
  useEffect(() => {
    console.log("hello");
    fetchProgressatstart();
  }, []);

  const handledownloadall = async () => {
    try {
      setShow(true);
      setDownloading(true);
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/generatall`, config);

      if (response.data.message === "S") {
        console.log("zip is created");
        // setDownloading(false)
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
        setDownloading(false);
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
        setDownloading(false);
      }
    }
  };

  const downloadzip = async () => {
    try {
      setLoading(true);
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await fetch(`${conf.baseurl}/downloadzip`, config);
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "pdfs.zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up after download
        window.URL.revokeObjectURL(url);
        setProgress(0);
        setShow(false);
        setZipbtnshow(false);
        setLoading(false);
      } else {
        console.error("Failed to download PDFs:", response.statusText);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.error("Error fetching progress:", error);
        console.error("Error:", error.message);
      }
    }
  };
  return (
    <div className="container">
      <div className="formWrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            height: "80vh",
            flexDirection: "row",
          }}
        >
          <div style={{ width: "50%" }}>
            <p style={{ fontSize: 18 }}>
              To generate report for all employees click on Generate All Reports
              button. It may take some time to generate all reports. You can see
              the progress of the process on right side of the screen. The
              reports can be downloaded after the process is finished.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Button
              onClick={handledownloadall}
              disabled={show ? true : false}
              style={
                show
                  ? { backgroundColor: "lightgray", color: "gray" }
                  : { backgroundColor: "#007bff", color: "white" }
              }
            >
              Generate All Reports
              <FaArrowDown style={{ marginLeft: 3, marginTop: 3 }} />
            </Button>

            {show && (
              <>
                <div style={{ height: 200, width: 200, marginTop: 20 }}>
                  <CircularProgressbar
                    value={progress}
                    text={`${progress}%`}
                  ></CircularProgressbar>
                </div>
                <Button
                  disabled={zipbtnshow ? false : true}
                  style={
                    zipbtnshow
                      ? {
                          marginTop: 20,
                          backgroundColor: "white",
                          color: "blue",
                        }
                      : {
                          marginTop: 20,
                          backgroundColor: "lightgray",
                          color: "gray",
                        }
                  }
                  onClick={downloadzip}
                >
                  {loading ? (
                    //    'Downloading...'
                    <span>
                      Downloading..
                      <Spin />
                    </span>
                  ) : (
                    "Download zip"
                  )}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statuspage;
