import React from "react";
import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";

const breadcrumbMap = {
  showdata: "Employee Data",
  report: "Generate Report",
  status: "Download All Reports",
  import: "Import",
  export: "Export",
  employeelist: "Employee Info",
  promotelist: "Promote Table",
  create: "Employee Info Form",
  form: "Employee Data Form",
  netincomeform: "Net Income Form",
};

const BreadcrumbComponent = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  // Get the last segment of the path
  const lastSegment = pathSnippets[pathSnippets.length - 1];
  const breadcrumbName = breadcrumbMap[lastSegment] || lastSegment;
  // console.log(breadcrumbName);

  return (
    <Breadcrumb style={{ marginLeft: 250, marginTop: 20 }}>
      <Breadcrumb.Item key={lastSegment}>
        <Link
          to={location.pathname}
          style={{ color: "black", fontSize: 18, textDecoration: "underline" }}
        >
          {/* {breadcrumbName} */}
        </Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
