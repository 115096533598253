import logo from "./logo.svg";
import "./App.css";
import Formpage from "./components/Formpage";
import Showdata from "./components/Showdata";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Nav from "./components/Nav";
import Report from "./components/Report";
import Exportpage from "./components/Exportpage";
import Importpage from "./components/Importpage";
import conf from "./config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loginpage from "./components/Loginpage";
import Cookies from "js-cookie";
import Statuspage from "./components/Statuspage";
import { useMyContext } from "./context/Usecontext";
import CreateEmployee from "./components/CreateEmployee";
import EmployeeList from "./components/EmployeeList";
import Promotetablelist from "./components/Promotetablelist";
import Editpromotetableform from "./components/Editpromotetablefrom";
import BreadcrumbComponent from "./components/Breadcrumps";
import NetIncomeForm from "./components/NetIncomeForm";
import Netincomepage from "./components/Netincomepage";

function App() {
  const [datafedit, setEditdata] = useState(null);
  const [islogin, setIslogin] = useState(false);
  const navigate = useNavigate();
  const { isAdmin, setIsAdmin } = useMyContext();
  const { fname, setFname } = useMyContext();
  const { lname, setLname } = useMyContext();
  const location = useLocation();

  useEffect(() => {
    // console.log("refreshing");

    const isLoggedIn = Cookies.get("isLoggedIn") === "true";
    if (isLoggedIn) {
      // console.log("User is logged in");
      setIslogin(true);
      const isadmin = Cookies.get("isAdmin");
      setIsAdmin(isadmin);
      const fname = Cookies.get("firstname");
      setFname(fname);
      const lname = Cookies.get("lastname");
      setLname(lname);
      console.log(location.pathname);
      if (location.pathname === "/") navigate("/showdata");
      else {
        navigate(location.pathname);
      }

      // Perform actions for a logged-in user, such as navigating to a specific page
    } else {
      // console.log("User is not logged in");
      setIslogin(false);
      navigate("/");

      // Perform actions for a non-logged-in user, such as redirecting to the login page
    }
  }, []);

  const editdata = async (id, navigate) => {
    try {
      console.log(id);
      // const response = await axios.get(`${conf.baseurl}/getedit/${id}`);
      const token = Cookies.get("token");
      console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/getedit/${id}`, config);
      console.log("Server response:", response.data);
      setEditdata(response.data);
      navigate("/form");
      // formRef.current.resetFields();
    } catch (error) {
      // console.log('Error submitting form:', error);
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.log("Error submitting form:", error);
      }
    }
  };

  const handleLoginSuccess = () => {
    setIslogin(true);
  };
  const handleLogout = (navigate) => {
    try {
      Cookies.remove("isLoggedIn");
      setIslogin(false);
      navigate("/");
    } catch (error) {
      console.error("Error removing cookie:", error);
    }
  };

  return (
    <div>
      <ToastContainer autoClose={2000}></ToastContainer>
      {islogin && (
        <Nav
          setEditdata={setEditdata}
          handleLogout={handleLogout}
          setIslogin={setIslogin}
        ></Nav>
      )}
      <BreadcrumbComponent></BreadcrumbComponent>
      <Routes>
        {islogin ? (
          <>
            <Route
              path="/form"
              element={
                <Formpage
                  datafedit={datafedit}
                  setEditdata={setEditdata}
                  setIslogin={setIslogin}
                />
              }
            ></Route>
            <Route
              path="/showdata"
              element={<Showdata editdata={editdata} setIslogin={setIslogin} />}
            ></Route>
            <Route
              path="/report"
              element={<Report setIslogin={setIslogin} />}
            ></Route>
            <Route
              path="/status"
              element={<Statuspage setIslogin={setIslogin} />}
            ></Route>
            <Route
              path="/import"
              element={<Importpage setIslogin={setIslogin} />}
            ></Route>
            <Route
              path="/create"
              element={<CreateEmployee setIslogin={setIslogin} />}
            ></Route>
            <Route
              path="/export"
              element={<Exportpage setIslogin={setIslogin} />}
            ></Route>
            <Route
              path="/employeelist"
              element={<EmployeeList setIslogin={setIslogin} />}
            ></Route>
            <Route
              path="/promotelist"
              element={<Promotetablelist setIslogin={setIslogin} />}
            ></Route>
            <Route
              path="/editpromotedata"
              element={<Editpromotetableform setIslogin={setIslogin} />}
            ></Route>
            <Route
              path="/netincomeform/:id/:year"
              element={<NetIncomeForm setIslogin={setIslogin} />}
            ></Route>
            <Route
              path="/netincome"
              element={<Netincomepage setIslogin={setIslogin} />}
            ></Route>
          </>
        ) : (
          <Route
            path="/"
            element={<Loginpage handleLoginSuccess={handleLoginSuccess} />}
          />
        )}
      </Routes>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
